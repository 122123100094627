import axios from "axios";
import React, { useEffect, useState } from "react";
import { NGROK } from "../../../APIs";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  IconButton,
  Paper,
  Radio,
  RadioGroup,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import TenantSelection from "../Components/TenantSelection";
import { myLocalStorage } from "../../../components/StorageHelper";
import { fetchTenantsData } from "../api";
import useUserStore from "../../../services/userStore";
import { Form, Formik } from "formik";
import CloseIcon from "@mui/icons-material/Close";
import ComputerIcon from "@mui/icons-material/Computer";
import CloudQueueIcon from "@mui/icons-material/CloudQueue";

import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  role: Yup.object().required("Role is required"),
  selectedMachine: Yup.array()
    .of(Yup.object())
    .required("Machines are required"),
});

const UserRoles = () => {
  const userData = useUserStore((state) => state.user);
  const tenant = myLocalStorage.getItem("latestTenant");
  const [selectedTenant, setSelectedTenant] = useState(tenant);
  const [tenantsList, setTenantsList] = useState([]);
  const [rolesData, setRolesData] = useState([]);
  const { role, email, userId, userRoleId, userRole } = useUserStore(
    (state) => state.user,
  );
  const [selectedRoleType, setSelectedRoleType] = useState(null);
  const [selectedRoleName, setSelectedRoleName] = useState(null);
  const [loading, setLoading] = useState(true);
  const [editedRole, setEditedRole] = useState(null);
  const [rolesList, setRolesList] = useState([]);
  const [selectedRole, setSelectedRole] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [radioRoleType, setRadioRoleType] = useState("");
  const [roleTypeFilter, setRoleTypeFilter] = useState("users");
  const [machineList, setMachineList] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  const [showExtraComputers, setShowExtraComputers] = useState({});

  const MAX_VISIBLE_COMPUTERS = 1;

  const handleShowMoreComputers = (email) => {
    setShowExtraComputers((prevState) => ({
      ...prevState,
      [email]: !prevState[email],
    }));
  };

  const computersToShow = (user) =>
    showExtraComputers[user.email]
      ? user.adComputerList.map((computer) => computer.dNSHostName)
      : user.adComputerList
          .map((computer) => computer.dNSHostName)
          .slice(0, MAX_VISIBLE_COMPUTERS);

  const hasMoreComputers = (user) =>
    user.adComputerList.length > MAX_VISIBLE_COMPUTERS;

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value.toLowerCase());
  };

  const handleEditClick = (index, user) => {
    setRoleTypeFilter(selectedRoleType);
    setSelectedRole(user.roleName);
    setEditedRole(user);
    setOpenDialog(true);
  };

  const handleSaveEdit = async (values, { setSubmitting }) => {
    try {
      const computerIdList = values.selectedMachine.map(
        (computer) => computer.id,
      );

      const computerListStr =
        computerIdList.length > 0 ? computerIdList.join(",") : "";

      const response = await axios.put(
        `${NGROK}/api/${selectedTenant.tenantName}/updateRoles`,
        values.role,
        {
          params: {
            roleType: selectedRoleType,
            email: editedRole.email,
            computerList: computerListStr,
          },
        },
      );

      console.log("Response:", response.data);

      setEditedRole(null);
      setOpenDialog(false);
    } catch (error) {
      console.error("Error updating role:", error);
    } finally {
      setSubmitting(false);
    }
  };

  const handleCancelEdit = () => {
    setEditedRole(null);
    setOpenDialog(false);
  };

  const fetchRolesList = async () => {
    const response = await axios.get(
      `${NGROK}/api/${selectedTenant.tenantName}/role-details`,
    );
    setRolesList(response?.data);
  };

  const fetchMachinesList = async () => {
    const response = await axios.get(
      `${NGROK}/api/${selectedTenant.tenantName}/getAllUserMachines`,
    );
    setMachineList(response?.data);
  };

  const filteredRoles = rolesList.filter((role) => {
    if (roleTypeFilter === "users") {
      return (
        role.roleType === "users" &&
        role.roleName !== "super-user" &&
        role.roleName !== "thirdparty-user"
      );
    }
    return role.roleType === "admin";
  });

  const getRolesUserDetails = async (isFirst = false) => {
    if (isFirst) {
      setLoading(true);
    }
    const response = await axios.get(
      `${NGROK}/api/${selectedTenant.tenantName}/getRolesLevelUsers`,
    );
    const fetchedRolesData = response?.data;

    setRolesData(fetchedRolesData);
    if (isFirst) {
      const firstRoleType = Object.keys(fetchedRolesData?.roles || [])[0];
      if (firstRoleType) {
        if (
          userData?.userRole === "epam-admin" ||
          userData?.userRole === "server-pam-admin"
        ) {
          setSelectedRoleType("users");
        } else {
          setSelectedRoleType(firstRoleType);
        }

        setSelectedRoleName(null);
      }
    }
    setLoading(false);
  };

  const roleNamesList = selectedRoleType
    ? Object.keys(rolesData.roles[selectedRoleType])
    : [];
  const users =
    selectedRoleType && selectedRoleName
      ? selectedRoleName === "ALL"
        ? Object.values(rolesData.roles[selectedRoleType]).flat()
        : rolesData.roles[selectedRoleType][selectedRoleName]
      : [];

  const filteredUsers = users?.filter((user) => {
    const searchLowerCase = searchTerm.toLowerCase();
    const matchesEmail = user.email.toLowerCase().includes(searchLowerCase);
    const matchesRoleName = user.roleName
      .toLowerCase()
      .includes(searchLowerCase);
    const matchesFirstName = user.firstName
      ?.toLowerCase()
      .includes(searchLowerCase);
    const matchesLastName = user.lastName
      ?.toLowerCase()
      .includes(searchLowerCase);

    const matchesDnsHostName = user.adComputerList?.some((computer) =>
      computer.dNSHostName.toLowerCase().includes(searchLowerCase),
    );

    return (
      matchesEmail ||
      matchesRoleName ||
      matchesFirstName ||
      matchesLastName ||
      matchesDnsHostName
    );
  });

  const handleCheckboxClick = (userEmail) => {
    setSelectedUsers((prevSelected) =>
      prevSelected.includes(userEmail)
        ? prevSelected.filter((email) => email !== userEmail)
        : [...prevSelected, userEmail],
    );
  };
  const handleSelectAllClick = () => {
    if (selectedUsers?.length === users?.length) {
      setSelectedUsers([]);
    } else {
      setSelectedUsers(users.map((user) => user.email));
    }
  };

  const isUserSelected = (userEmail) => selectedUsers?.includes(userEmail);
  const isAllSelected =
    selectedUsers?.length === users?.length && users?.length > 0;

  const deleteTheSelectedUser = async () => {
    let realmpasskey = "";
    let realmLegacy = "";
    if (selectedRoleType === "admin") {
      realmpasskey = "whiteswan_tenants";
      realmLegacy = "whiteswan_tenants_legacy";
    } else {
      let realm = selectedTenant?.tenantName;
      if (realm.endsWith(".local")) {
        const legacyRealm = realm.replace(".local", "-legacy.local");
        realmpasskey = realm;
        realmLegacy = legacyRealm;
      } else if (realm.endsWith("-legacy.local")) {
        const passkeyRealm = realm.replace("-legacy.local", ".local");

        realmpasskey = passkeyRealm;
        realmLegacy = realm;
      } else if (realm.endsWith("_Legacy")) {
        const passkeyRealm = realm.replace("_Legacy", "");

        realmpasskey = passkeyRealm;
        realmLegacy = realm;
      } else if (!realm.endsWith("_Legacy")) {
        const legacyRealm = `${realm}_Legacy`;

        realmpasskey = realm;
        realmLegacy = legacyRealm;
      }
    }

    const response = await axios.put(
      `${NGROK}/api/keycloak/${realmpasskey}/users`,
      selectedUsers,
    );

    const response2 = await axios.put(
      `${NGROK}/api/keycloak/${realmLegacy}/users`,
      selectedUsers,
    );

    if (response?.data === true || response2?.data === true) {
      setSelectedUsers([]);
    }
  };

  useEffect(() => {
    if (email) fetchTenantsData(email, setTenantsList);
  }, [email]);

  useEffect(() => {
    if (tenantsList.length === 1) return setSelectedTenant(tenantsList[0]);

    if (tenantsList?.length > 1) {
      const latestTenantName =
        myLocalStorage.getItem("latestTenant")?.tenantName;

      const tenant = tenantsList.find(
        (tenant) => tenant.tenantName === latestTenantName,
      );

      if (tenant) setSelectedTenant(tenant);
      else setSelectedTenant(tenantsList[0]);
    }
  }, [tenantsList]);

  useEffect(() => {
    getRolesUserDetails(true);
    fetchRolesList();
    fetchMachinesList();
    setEditedRole(null);

    const interval = setInterval(() => {
      getRolesUserDetails(false);
      fetchRolesList();
      fetchMachinesList();
    }, 5000);
    return () => clearInterval(interval);
  }, [selectedTenant]);

  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        sx={{ mb: 2 }}
      >
        <Box>
          <TenantSelection
            selectedTenant={selectedTenant}
            setSelectedTenant={setSelectedTenant}
          />
        </Box>
        <Stack
          spacing={5}
          direction={"row"}
          sx={{
            width: "100%",
            justifyContent: "flex-end",
          }}
        >
          <TextField
            label="Search users"
            variant="outlined"
            fullWidth
            sx={{ maxWidth: 400, flexGrow: 1 }}
            margin="normal"
            onChange={handleSearchChange}
            value={searchTerm}
          />
          <Button
            variant="contained"
            color="error"
            disabled={selectedUsers.length === 0}
            onClick={() => deleteTheSelectedUser()}
          >
            Delete Users
          </Button>
        </Stack>
      </Box>

      <Box padding={3}>
        {loading ? (
          <CircularProgress />
        ) : rolesData.length === 0 ? (
          <Typography variant="body1"> No data found</Typography>
        ) : (
          <Stack direction="row" spacing={2}>
            <Box width="15%">
              <TableContainer component={Paper}>
                <Table
                  sx={{
                    height: "fit-content",
                    "& th": {
                      background: "#233044",
                      color: "#fff",
                    },
                    "& td, & th": {
                      border: "1px solid #233044",
                      fontSize: "18px",
                    },
                  }}
                  size="large"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell>Role Types</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Object.keys(rolesData?.roles)
                      .map((roleType, index) => {
                        if (
                          userRole === "server-pam-admin" ||
                          userRole === "epam-admin"
                        ) {
                          return roleType === "users" ? roleType : null;
                        }

                        return roleType;
                      })
                      .filter(Boolean)
                      .map((roleType, index) => (
                        <TableRow
                          key={index}
                          selected={selectedRoleType === roleType}
                          onClick={() => {
                            setSelectedRoleType(roleType);
                            setSelectedRoleName(null);
                            setEditedRole(null);
                          }}
                          sx={{
                            "&:hover": {
                              background: "#f5f5f5",
                              cursor: "pointer",
                            },
                            "& td, & th": {
                              border: "1px solid #233044",
                              fontSize: "8px",
                            },
                            "&.Mui-selected": {
                              background: "#233044 !important",
                              "& td, & th": {
                                color: "#fff",
                              },
                            },
                          }}
                        >
                          <TableCell>
                            <Typography
                              sx={{
                                fontSize: 18,
                              }}
                            >
                              {roleType}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>

            <Box width="15%">
              <TableContainer component={Paper}>
                <Table
                  sx={{
                    height: "fit-content",
                    "& th": {
                      background: "#233044",
                      color: "#fff",
                    },
                    "& td, & th": {
                      border: "1px solid #233044",
                      fontSize: "18px",
                    },
                  }}
                  size="large"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell>Role Names</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {roleNamesList.length > 0 ? (
                      <>
                        {userRole !== "epam-admin" &&
                          userRole !== "server-pam-admin" && (
                            <TableRow
                              selected={"ALL" === selectedRoleName}
                              onClick={() => {
                                setSelectedRoleName("ALL");
                                setEditedRole(null);
                              }}
                              sx={{
                                "&:hover": {
                                  background: "#f5f5f5",
                                  cursor: "pointer",
                                },
                                "& td, & th": {
                                  border: "1px solid #233044",
                                  fontSize: "8px",
                                },
                                "&.Mui-selected": {
                                  background: "#233044 !important",
                                  "& td, & th": {
                                    color: "#fff",
                                  },
                                },
                              }}
                            >
                              <TableCell>
                                <Typography
                                  sx={{
                                    fontSize: 18,
                                  }}
                                >
                                  All
                                </Typography>
                              </TableCell>
                            </TableRow>
                          )}

                        {roleNamesList
                          .filter((roleName) => {
                            if (userRole === "epam-admin") {
                              return (
                                roleName === "epam-user" &&
                                roleName !== "super-user"
                              );
                            }
                            if (userRole === "server-pam-admin") {
                              return (
                                roleName !== "epam-user" &&
                                roleName !== "super-user"
                              );
                            }
                            return roleName !== "super-user";
                          })
                          .map((roleName, index) => (
                            <TableRow
                              key={index}
                              selected={roleName === selectedRoleName}
                              onClick={() => {
                                setSelectedRoleName(roleName);
                                setEditedRole(null);
                              }}
                              sx={{
                                "&:hover": {
                                  background: "#f5f5f5",
                                  cursor: "pointer",
                                },
                                "& td, & th": {
                                  border: "1px solid #233044",
                                  fontSize: "8px",
                                },
                                "&.Mui-selected": {
                                  background: "#233044 !important",
                                  "& td, & th": {
                                    color: "#fff",
                                  },
                                },
                              }}
                            >
                              <TableCell>
                                <Typography
                                  sx={{
                                    fontSize: 18,
                                  }}
                                >
                                  {roleName}
                                </Typography>
                              </TableCell>
                            </TableRow>
                          ))}
                      </>
                    ) : (
                      <TableRow>
                        <TableCell>No roles found</TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>

            <Box width="70%">
              <TableContainer
                component={Paper}
                sx={{
                  height: "fit-content",
                  width: "100%",
                }}
              >
                <Table
                  sx={{
                    height: "fit-content",
                    width: "100%",
                    "& th": {
                      background: "#233044",
                      color: "#fff",
                    },
                    "& td, & th": {
                      border: "1px solid #233044",
                      fontSize: "18px",
                    },
                  }}
                  size="large"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={isAllSelected}
                          indeterminate={
                            selectedUsers.length > 0 &&
                            selectedUsers.length < users.length
                          }
                          onChange={handleSelectAllClick}
                        />
                      </TableCell>
                      <TableCell>First Name</TableCell>
                      <TableCell>Last Name</TableCell>
                      <TableCell>Email</TableCell>
                      <TableCell>Role</TableCell>
                      <TableCell>Created At</TableCell>

                      <TableCell>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredUsers?.length > 0 ? (
                      filteredUsers.map((user, index) => {
                        const createdAtDate = new Date(user.createdAt);
                        const formattedCreatedAt = `${String(createdAtDate.getUTCMonth() + 1).padStart(2, "0")}/${String(
                          createdAtDate.getUTCDate(),
                        ).padStart(
                          2,
                          "0",
                        )}/${createdAtDate.getUTCFullYear()} ${String(
                          createdAtDate.getUTCHours(),
                        ).padStart(
                          2,
                          "0",
                        )}:${String(createdAtDate.getUTCMinutes()).padStart(2, "0")}`;
                        const isOnline = user.lastAccess !== null;
                        const dotColor = isOnline ? "green" : "red";
                        const endpointComputers = Array.isArray(
                          user.adComputerList,
                        )
                          ? user.adComputerList.map(
                              (computer) => computer.dNSHostName,
                            )
                          : [];

                        return (
                          <TableRow key={index}>
                            <TableCell padding="checkbox">
                              <Checkbox
                                checked={isUserSelected(user.email)}
                                onChange={() => handleCheckboxClick(user.email)}
                              />
                            </TableCell>
                            <TableCell>{user.firstName}</TableCell>
                            <TableCell>{user.lastName}</TableCell>
                            <TableCell>
                              <Stack direction={"column"}>
                                <Typography
                                  sx={{
                                    fontSize: 18,
                                    display: "inline-flex",
                                    alignItems: "center",
                                  }}
                                >
                                  {user.isFromSSO && (
                                    <CloudQueueIcon sx={{ marginRight: 1 }} />
                                  )}
                                  {user.email}
                                </Typography>
                                <Box>
                                  {endpointComputers.length > 0 ? (
                                    <Stack direction={"row"} spacing={2}>
                                      <ComputerIcon />
                                      <Typography
                                        variant="body2"
                                        sx={{
                                          maxWidth: 200,
                                          overflowWrap: "break-word",
                                          textAlign: "left",
                                        }}
                                      >
                                        {computersToShow(user).join(", ")}
                                      </Typography>
                                      {!showExtraComputers[user.email] &&
                                        hasMoreComputers(user) && (
                                          <Typography
                                            variant="body2"
                                            color="primary"
                                            sx={{
                                              cursor: "pointer",
                                              textDecoration: "underline",
                                              textAlign: "left",
                                              marginLeft: "8px",
                                            }}
                                            onClick={() =>
                                              handleShowMoreComputers(
                                                user.email,
                                              )
                                            }
                                          >
                                            Show More
                                          </Typography>
                                        )}
                                    </Stack>
                                  ) : null}
                                </Box>
                              </Stack>
                            </TableCell>
                            <TableCell>{user.roleName}</TableCell>
                            <TableCell>{formattedCreatedAt}</TableCell>

                            <TableCell>
                              <Button
                                variant="contained"
                                disabled={user.roleName === "thirdparty-user"}
                                onClick={() => handleEditClick(index, user)}
                              >
                                Edit
                              </Button>
                            </TableCell>
                          </TableRow>
                        );
                      })
                    ) : (
                      <TableRow>
                        <TableCell align="center" colSpan={8}>
                          No users found
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Stack>
        )}
      </Box>

      <Dialog
        open={openDialog}
        onClose={handleCancelEdit}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>
          Edit Role
          <IconButton
            aria-label="close"
            onClick={handleCancelEdit}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <Formik
          initialValues={{
            role:
              rolesList.find(
                (data) => data.roleName === editedRole?.roleName,
              ) || "",
            selectedMachine:
              editedRole?.adComputerList?.length > 0
                ? editedRole?.adComputerList
                : [],
          }}
          validationSchema={validationSchema}
          onSubmit={handleSaveEdit}
        >
          {({
            values,
            setFieldValue,
            errors,
            touched,
            handleSubmit,
            isSubmitting,
          }) => (
            <Form onSubmit={handleSubmit}>
              <DialogContent>
                <Box mb={2}>
                  <Autocomplete
                    options={filteredRoles}
                    getOptionLabel={(option) => option.roleName}
                    value={values.role}
                    disabled={userData.userRole === "epam-admin"}
                    onChange={(event, value) => {
                      setFieldValue("role", value);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select Role"
                        variant="outlined"
                        error={touched.role && !!errors.role}
                        helperText={touched.role && errors.role}
                        fullWidth
                      />
                    )}
                  />
                </Box>

                {selectedRoleType === "users" &&
                values.role.roleName !== "thirdparty-user" ? (
                  <Box sx={{ mb: 2 }}>
                    <Autocomplete
                      multiple
                      options={machineList}
                      value={values.selectedMachine || []}
                      getOptionDisabled={() => {
                        const selectedMachinesCount =
                          values?.selectedMachine?.length || 0;
                        return selectedMachinesCount >= 3;
                      }}
                      getOptionLabel={(option) =>
                        option.dNSHostName ||
                        option.IPV4Address ||
                        "Unknown Machine"
                      }
                      onChange={(event, value) => {
                        const uniqueMachines = value.filter(
                          (machine, index, self) =>
                            index ===
                            self.findIndex(
                              (m) =>
                                m.dNSHostName?.toLowerCase() ===
                                  machine.dNSHostName?.toLowerCase() &&
                                m.id === machine.id,
                            ),
                        );
                        if (uniqueMachines.length <= 3) {
                          setFieldValue("selectedMachine", uniqueMachines);
                        } else {
                          alert("You can select a maximum of 3 machines.");
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Select Machines "
                          variant="outlined"
                          error={
                            touched.selectedMachine && !!errors.selectedMachine
                          }
                          helperText={
                            touched.selectedMachine && errors.selectedMachine
                          }
                          fullWidth
                        />
                      )}
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                          <Chip
                            variant="outlined"
                            label={
                              option.dNSHostName ||
                              option.IPV4Address ||
                              "Unknown Machine"
                            }
                            {...getTagProps({ index })}
                          />
                        ))
                      }
                    />
                  </Box>
                ) : null}
              </DialogContent>

              <DialogActions>
                <Button
                  onClick={handleCancelEdit}
                  variant="outlined"
                  color="secondary"
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  disabled={isSubmitting}
                  variant="contained"
                  color="primary"
                >
                  Save
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </>
  );
};

export default UserRoles;
