import {
  Alert,
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputAdornment,
  Paper,
  Snackbar,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import useUserStore from "../../../services/userStore";
import { NGROK } from "../../../APIs";
import axios from "axios";
import { myLocalStorage } from "../../../components/StorageHelper";
import SearchIcon from "@mui/icons-material/Search";
import moment from "moment";
import { Link, useNavigate } from "react-router-dom";
import { awsIcons } from "../AWS/AWSIcons";

const AzureUsers = () => {
  const [usersData, setUsersData] = useState(null);
  const [selectedTenantName, setSelectedTenantName] = useState(null);
  const [tenantList, setTenantList] = useState([]);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [alertSeverity, setAlertSeverity] = useState("success");
  const { role, userId, email } = useUserStore((state) => state.user);
  const userData = useUserStore((state) => state.user);
  const [loading, setLoading] = useState(true);
  const [isAzureUserConfigured, setIsAzureUserConfigured] = useState(false);
  const [configureAccountModalOpen, setConfigureAccountModalOpen] =
    useState(false);
  const [isAzureConfigurationLoading, setIsAzureConfigurationLoading] =
    useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [isSyncing, setIsSyncing] = useState(false);
  const navigate = useNavigate();
  const [isButtonEnabled, setIsButtonEnabled] = useState(false);
  const [azureID, setAzureID] = useState("");
  const [displayName, setDisplayName] = useState("");
  const [alertMessage, setAlertMessage] = useState("");

  const latestTenant = myLocalStorage.getItem("latestTenant")?.tenantName;

  const MAX_ITEMS_DISPLAYED = 2;

  const ondemandButton = async () => {
    setIsSyncing(true);
    const response = await axios.get(`${NGROK}/api/azure-sync/onDemand`, {
      params: {
        tenantName: selectedTenantName,
      },
    });
    setIsSyncing(false);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };
  const handleTenantChange = (value) => {
    const tenant = tenantList.find((tenant) => tenant.tenantName === value);
    if (tenant !== undefined) {
      setSelectedTenantName(tenant.tenantName);
      myLocalStorage.setItem("latestTenant", tenant);
    }
  };
  const getAllUsers = async () => {
    const response = await axios.get(
      `${NGROK}/api/azure/v1/users?tenantName=${latestTenant}`,
    );
    if (response?.status === 200) return response.data;
    else return [];
  };
  const getAllGroupsAndRoles = async () => {
    const response = await axios.get(
      `${NGROK}/api/azure/v1/users/getRolesAndGroups?tenantName=${latestTenant}`,
    );
    if (response?.status === 200) return response.data;
    else return [];
  };
  const getCombinedUserData = (users, rolesAndGroups) => {
    const combinedData = rolesAndGroups.map((roleGroup) => {
      const sanitizedGroups = roleGroup.groups.map((group) =>
        group === "null" ? null : group,
      );
      const sanitizedRoles = roleGroup.roleDefinitions.map((def) =>
        def === "null" ? null : def,
      );

      const user = users.find((u) => u.id === roleGroup.id);
      return {
        ...roleGroup,
        ...user,
        groups: sanitizedGroups,
        roleDefinitions: sanitizedRoles,
      };
    });
    return combinedData;
  };

  const fetchData = async () => {
    try {
      const [users, groupsAndRoles] = await Promise.all([
        getAllUsers(),
        getAllGroupsAndRoles(),
      ]);
      const combineUserData = getCombinedUserData(users, groupsAndRoles);
      console.log(combineUserData);
      setUsersData(combineUserData);
    } catch (e) {}
    setLoading(false);
  };
  const fetchAzureUserConfiguration = async (isInital = false) => {
    try {
      const response = await axios.get(
        `${NGROK}/api/azureUserConfigure/v1/get?tenantName=${latestTenant}&email=${email}`,
      );
      if (response?.data) {
        let { azureId, displayName, id } = response?.data;
        setIsAzureUserConfigured(azureId != null ? false : true);
        setConfigureAccountModalOpen(azureId != null ? false : true);

        if (azureId && displayName) {
          setIsAzureConfigurationLoading(true);
          myLocalStorage.setItem("userDetails", JSON.stringify(response?.data));
          fetchAzureConfigureDetails(azureId, displayName, id, isInital);
        }
      } else {
        setIsAzureUserConfigured(true);
        setConfigureAccountModalOpen(false);
      }
    } catch (e) {
      setIsAzureUserConfigured(true);
      setConfigureAccountModalOpen(false);
    }
  };

  const getSpecificUsers = async (id, displayName) => {
    const response = await axios.get(
      `${NGROK}/api/azure/v1/users/getRolesAndGroups?tenantName=${latestTenant}&azureId=${id}`,
    );
    return response.data;
  };

  const fetchAzureConfigureDetails = async (
    azureId,
    displayName,
    id,
    isInital,
  ) => {
    try {
      const [users] = await Promise.all([
        getSpecificUsers(azureId, displayName),
      ]);

      const specificUserdata = users[0];
      setIsAzureConfigurationLoading(false);
      navigate("/azure-directory/azureusers/userDetails", {
        state: {
          user: specificUserdata,
          groups: specificUserdata?.groups,
          roles: specificUserdata?.roleDefinitions,
          isconfigured: false,
        },
      });
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data", error);
      setLoading(false);
    }
  };

  const validateAndConfigureTheAzureUser = async () => {
    try {
      let requestbody = {
        azureId: azureID,
        displayName: displayName,
        wsTenantName: latestTenant,
        email: email,
      };
      const response = await axios.post(
        `${NGROK}/api/azureUserConfigure/v1/create`,
        requestbody,
      );
      if (response?.data) {
        setAlertSeverity("success");
        setAlertMessage("Azure User successfully configured!");
        setOpenSnackbar(true);
        fetchAzureUserConfiguration(true);
      }
    } catch (ex) {
      setAlertSeverity("error");
      setAlertMessage("Failed to configure AWS user. Please try again.");
      setOpenSnackbar(true);
      console.error(ex);
    }
    setConfigureAccountModalOpen(false);
  };

  const renderItemsWithMoreLink = (items, maxItems, user) => {
    if (items.length > 0) {
      if (items.length <= maxItems) {
        return items.join(", ");
      } else {
        const displayedItems = items.slice(0, maxItems).join(", ");
        const remainingCount = items.length - maxItems;
        return (
          <>
            {displayedItems},{" "}
            <Link
              to={`/azure-directory/azureusers/userDetails`}
              state={{
                user,
                groups: user.groups,
                roles: user.roleDefinitions,
                isconfigured: false,
              }}
            >
              {remainingCount} more
            </Link>
          </>
        );
      }
    } else {
      <Typography variant="body2">-</Typography>;
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "azureID") {
      setAzureID(value);
    } else if (name === "displayName") {
      setDisplayName(value);
    }

    if (azureID && displayName) {
      setIsButtonEnabled(true);
    } else {
      setIsButtonEnabled(false);
    }
  };

  useEffect(() => {
    if (role !== "TENANT_USER") {
      fetchData();
      const interval = setInterval(() => {
        fetchData();
      }, 10000);
      return () => clearInterval(interval);
    } else {
      fetchAzureUserConfiguration();
    }
  }, [latestTenant, selectedTenantName]);

  useEffect(() => {
    if (tenantList.length === 1)
      return setSelectedTenantName(tenantList[0].tenantName);

    if (tenantList?.length > 1) {
      const latestTenantName =
        myLocalStorage.getItem("latestTenant")?.tenantName;

      const tenant = tenantList.find(
        (tenant) => tenant.tenantName === latestTenantName,
      );
      if (tenant) setSelectedTenantName(tenant.tenantName);
      else setSelectedTenantName(tenantList[0].tenantName);
    }
  }, [tenantList]);

  useEffect(() => {
    const fetchTenants = async () => {
      const response = await axios.get(`${NGROK}/api/get-all-domains`);
      setTenantList(response.data);
    };
    fetchTenants();
  }, [userData]);

  const filteredUsers = usersData
    ? usersData.filter((user) =>
        user.displayName.toLowerCase().includes(searchTerm.toLowerCase()),
      )
    : [];

  useEffect(() => {
    if (azureID.trim() && displayName.trim()) {
      setIsButtonEnabled(true);
    } else {
      setIsButtonEnabled(false);
    }
  }, [azureID, displayName]);

  return (
    <>
      <Box>
        <Snackbar
          open={openSnackbar}
          autoHideDuration={6000}
          onClose={() => setOpenSnackbar(false)}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert
            onClose={() => setOpenSnackbar(false)}
            severity={alertSeverity}
            sx={{ width: "100%" }}
          >
            {alertMessage}
          </Alert>
        </Snackbar>
        {role !== "TENANT_USER" ? (
          <>
            <Stack spacing={5}>
              <Box display="flex" justifyContent="flex-start">
                <Stack
                  direction={"row"}
                  spacing={2}
                  sx={{
                    width: "100%",
                    justifyContent: "space-between",
                  }}
                >
                  <Stack direction={"row"} spacing={2}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "15px",
                      }}
                    >
                      {tenantList?.length > 0 ? (
                        <Autocomplete
                          disablePortal
                          id="combo-box-demo"
                          value={selectedTenantName ? selectedTenantName : " "}
                          options={tenantList.map(
                            (tenant) => tenant.tenantName,
                          )}
                          sx={{ width: 300 }}
                          renderInput={(params) => (
                            <TextField {...params} label="Tenant List" />
                          )}
                          onChange={(e, value) => {
                            handleTenantChange(value);
                          }}
                        />
                      ) : null}
                    </Box>
                    <TextField
                      placeholder="Search users..."
                      value={searchTerm}
                      onChange={handleSearchChange}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Stack>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    <Button
                      variant="outlined"
                      color="primary"
                      sx={{ ml: "auto" }}
                      onClick={ondemandButton}
                      disabled={isSyncing}
                    >
                      {isSyncing ? "Syncing" : "Sync"}
                    </Button>
                  </Box>
                </Stack>
              </Box>
              {loading && role !== "TENANT_USER" ? (
                <Box display={"flex"} p={5}>
                  <CircularProgress />
                </Box>
              ) : !usersData ? (
                <Typography
                  variant="body2"
                  color="textSecondary"
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"center"}
                >
                  No datas available
                </Typography>
              ) : filteredUsers.length === 0 ? (
                <Typography
                  variant="body2"
                  color="textSecondary"
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"center"}
                >
                  No Users available
                </Typography>
              ) : (
                <TableContainer
                  style={{ height: "fit-content" }}
                  component={Paper}
                >
                  <Table
                    sx={{
                      height: "fit-content",
                      maxWidth: "100%",
                      "& th": {
                        background: "#233044",
                        color: "#fff",
                      },
                      "& td, & th": {
                        border: "1px solid #233044",
                        fontSize: "16px",
                      },
                    }}
                    size="large"
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell align="center">
                          <Box
                            display="flex"
                            alignItems="center"
                            justifyContent={"center"}
                          >
                            {awsIcons.user}
                            <Box ml={2}>
                              <Typography variant="h6">User Name</Typography>
                            </Box>
                          </Box>
                        </TableCell>
                        <TableCell align="center">
                          <Box
                            display="flex"
                            alignItems="center"
                            justifyContent={"center"}
                          >
                            {awsIcons.groups}
                            <Box ml={2}>
                              <Typography variant="h6">Groups</Typography>
                            </Box>
                          </Box>
                        </TableCell>

                        <TableCell align="center">
                          <Box
                            display="flex"
                            alignItems="center"
                            justifyContent={"center"}
                          >
                            {awsIcons.roles}
                            <Box ml={2}>
                              <Typography variant="h6">Roles</Typography>
                            </Box>
                          </Box>
                        </TableCell>
                        <TableCell align="center">
                          <Box
                            display="flex"
                            alignItems="center"
                            justifyContent={"center"}
                          >
                            <Box ml={2}>
                              <Typography variant="h6">Created At</Typography>
                            </Box>
                          </Box>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {filteredUsers.map((user) => (
                        <TableRow key={user.azureId}>
                          <TableCell align="center">
                            <Link
                              to={`/azure-directory/azureusers/userDetails`}
                              state={{
                                user,
                                groups: user.groups,
                                roles: user.roleDefinitions,
                                isconfigured: false,
                              }}
                            >
                              {user.displayName}
                            </Link>
                          </TableCell>
                          <TableCell align="center">
                            {user?.groups?.length > 0
                              ? renderItemsWithMoreLink(
                                  user?.groups,
                                  MAX_ITEMS_DISPLAYED,
                                  user,
                                  user?.groups,
                                )
                              : "-"}
                          </TableCell>

                          <TableCell align="center">
                            {user?.groups?.length > 0
                              ? renderItemsWithMoreLink(
                                  user?.roleDefinitions,
                                  MAX_ITEMS_DISPLAYED,
                                  user,
                                  user?.roleDefinitions,
                                )
                              : "-"}
                          </TableCell>
                          <TableCell align="center">
                            <Typography variant="h6">
                              {moment(user.syncedAt).format(
                                "DD/MM/YYYY HH:mm:ss",
                              )}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
            </Stack>
          </>
        ) : (
          <>
            {isAzureUserConfigured ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "65vh",
                }}
              >
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => setConfigureAccountModalOpen(true)}
                >
                  Configure Azure account
                </Button>
                <Dialog
                  open={configureAccountModalOpen}
                  onClose={() => {
                    setConfigureAccountModalOpen(false);
                  }}
                >
                  <DialogTitle>Azure User Configuration</DialogTitle>
                  <DialogContent>
                    <TextField
                      margin="dense"
                      label="Display Name"
                      name="displayName"
                      fullWidth
                      variant="outlined"
                      value={displayName}
                      onChange={handleInputChange}
                    />
                    <TextField
                      autoFocus
                      margin="dense"
                      label="Azure ID"
                      name="azureID"
                      fullWidth
                      variant="outlined"
                      value={azureID}
                      onChange={handleInputChange}
                    />
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={() => setConfigureAccountModalOpen(false)}>
                      Cancel
                    </Button>
                    <Button
                      variant="contained"
                      disabled={!isButtonEnabled}
                      onClick={() => {
                        validateAndConfigureTheAzureUser();
                      }}
                    >
                      Validate
                    </Button>
                  </DialogActions>
                </Dialog>
              </Box>
            ) : isAzureConfigurationLoading ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "65vh",
                }}
              >
                <CircularProgress />
              </Box>
            ) : null}
          </>
        )}
      </Box>
    </>
  );
};

export default AzureUsers;
