import React, { useState } from "react";
import { awsIcons, azureIcons } from "../../AWS/AWSIcons";
import { myLocalStorage } from "../../../../components/StorageHelper";
import {
  Box,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";

const gcpResources = [
  {
    id: "VM",
    name: "Virtual Machines",
    description:
      "Google Cloud Virtual Machines (VMs) are scalable, flexible compute resources that can be customized to run applications, manage workloads, and handle data processing.",
    icon: azureIcons.vm,
  },
  {
    id: "STRGS",
    name: "Storages",
    description:
      "Google Cloud Storage offers scalable and secure object storage for storing and accessing your data, whether it's a small amount or a massive scale.",
    icon: azureIcons.strgs,
  },
  {
    id: "DB",
    name: "Databases",
    description:
      "Google Cloud Databases provide managed database services that are secure, reliable, and optimized for high performance and scalability.",
    icon: azureIcons.database,
  },
];
const AccessGCPResources = ({ user, groups, roles }) => {
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedResource, setSelectedResource] = useState(null);

  const tenantName = myLocalStorage.getItem("latestTenant")?.tenantName;
  const filteredGCPResources = gcpResources.filter((resource) =>
    resource.name.toLowerCase().includes(searchQuery.toLowerCase()),
  );
  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  return (
    <>
      <Stack direction={"row"} spacing={5}>
        <Box>
          <TableContainer component={Paper}>
            <Table
              sx={{
                height: "fit-content",
                "& th": {
                  background: "#233044",
                  color: "#fff",
                },
                "& td, & th": {
                  border: "1px solid #233044",
                  fontSize: "18px",
                },
              }}
              size="large"
            >
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Box display="flex" alignItems="center">
                      {awsIcons.resources}
                      <Box ml={2}>
                        <Typography
                          variant="h6"
                          sx={{
                            fontSize: "18px",
                          }}
                        >
                          Resource Details
                        </Typography>
                      </Box>
                    </Box>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell colSpan={1}>
                    <TextField
                      variant="outlined"
                      placeholder="Search Resources"
                      value={searchQuery}
                      onChange={handleSearchChange}
                      fullWidth
                    />
                  </TableCell>
                </TableRow>
                {filteredGCPResources.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={1} align="center">
                      <Typography>No resources found</Typography>
                    </TableCell>
                  </TableRow>
                ) : (
                  filteredGCPResources.map((resource) => (
                    <TableRow
                      key={resource.id}
                      selected={resource === selectedResource}
                      onClick={() => {}}
                      sx={{
                        "&:hover": {
                          background: "#f5f5f5",
                          cursor: "pointer",
                        },
                        "& td, & th": {
                          border: "1px solid #233044",
                          fontSize: "18px",
                        },
                        "&.Mui-selected": {
                          background: "#233044 !important",
                          "& td, & th": {
                            color: "#fff",
                          },
                        },
                      }}
                    >
                      <TableCell>
                        <Stack
                          direction={"row"}
                          spacing={5}
                          display="flex"
                          alignItems="center"
                        >
                          <div>{resource.icon}</div>
                          <Box ml={2}>
                            <Typography
                              variant="h6"
                              sx={{
                                fontSize: "18px",
                              }}
                            >
                              {resource.name}
                            </Typography>
                            <Typography
                              variant="body2"
                              sx={{
                                maxWidth: "350px",
                                wordWrap: "break-word",
                                whiteSpace: "normal",
                              }}
                            >
                              {resource.description}
                            </Typography>
                          </Box>
                        </Stack>
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        {/**Add the publish resource place */}
      </Stack>
    </>
  );
};

export default AccessGCPResources;
